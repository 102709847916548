import Swiper from 'swiper/bundle';
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function business(){
	fsLightboxInstances["first-lightbox"].props.exitFullscreenOnClose = true
	fsLightboxInstances["out-lightbox"].props.exitFullscreenOnClose = true
	fsLightboxInstances["fai-lightbox"].props.exitFullscreenOnClose = true
	fsLightboxInstances["faiarc-lightbox"].props.exitFullscreenOnClose = true
	fsLightboxInstances["faimelt-lightbox"].props.exitFullscreenOnClose = true
	fsLightboxInstances["faicr-lightbox"].props.exitFullscreenOnClose = true
	fsLightboxInstances["office-lightbox"].props.exitFullscreenOnClose = true
	fsLightboxInstances["map-lightbox"].props.exitFullscreenOnClose = true
}
