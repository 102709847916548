import Swiper from 'swiper/bundle';
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import Chart from 'chart.js/auto';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let count = 0

export function index(){
  const indexSwipe = document.getElementById('indexswipe');
  if(indexSwipe){
    new Swiper(indexSwipe, {
      slidesPerView: 'auto',
      loop: true,
      speed: 8000,
      autoplay: {
        delay: 10,
        disableOnInteraction: false,
      },
    })
  }

  const chart = document.getElementById('salesChart');
  if(chart){
    ScrollTrigger.create({
      trigger: chart,
      //markers: true,
      start: "top bottom",
      once: true,
      onEnter: () => {
        chartEvent(chart)
      }
    });
  }

  const countScore = document.getElementById('countScore');
  if(countScore){
    ScrollTrigger.create({
      trigger: countScore,
      //markers: true,
      start: "top bottom",
      once: true,
      onEnter: () => {
        counter(countScore)
      }
    });
  }



  const firstview = document.getElementById('firstview')
  if(firstview){
    firstview.classList.add('active')
    const fv = document.getElementById('fvslide');
    if(fv){
      new Swiper(fv, {
        spaceBetween: 0,
        effect: 'fade',
        speed: 2000,
        loop: true,
        fadeEffect: { 
          crossFade: true
        },
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
      });
    }

    window.addEventListener('resize', setFillHeight)
    setFillHeight()
  }

  const videoArea = document.getElementById('videoarea');
  const videoAreaBox = document.getElementById('videoareabox');
  const videoAreaBoxMin = document.getElementById('videoareaboxmin');
  const largeVideo = document.getElementById('largevideo');
  if(videoArea){
    ScrollTrigger.create({
      trigger: videoArea,
      pin: videoArea,
      //markers: true,
      start: "top top",
      end: "bottom -25%",
    });
    gsap.fromTo(
      videoArea,
      {
        opacity: 1,
      },
      {
        borderRadius: 0,
        borderWidth: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          ease: Power2.easeIn,
          trigger: videoArea,
          start: "top top",
          end: "top -50%",
          scrub: true,
          //markers: true
        },
      }
    )
    gsap.fromTo(
      videoAreaBox,
      {
        opacity: 1,
      },
      {
        borderRadius: 0,
        borderWidth: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          ease: Power2.easeIn,
          trigger: videoArea,
          start: "top top",
          end: "top -50%",
          scrub: true,
          //markers: true
        },
      }
    )
    gsap.fromTo(
      videoAreaBoxMin,
      {
        opacity: .32,
      },
      {
        opacity: .64,
        filter: 'blur(10px)',
        duration: 1,
        scrollTrigger: {
          ease: Power2.easeIn,
          trigger: videoArea,
          start: "top top",
          end: "top -50%",
          scrub: true,
          //markers: true
        },
      }
    )
    gsap.fromTo(
      largeVideo,
      {
        scale: 1.04,
      },
      {
        scale: 1,
        duration: 1,
        scrollTrigger: {
          ease: Power2.easeIn,
          trigger: videoArea,
          start: "top top",
          end: "top -50%",
          scrub: true,
          //markers: true
        },
      }
    )
  }

};

function chartEvent(chart){
  let context = chart.getContext('2d')
  new Chart(context, {
    type: 'bar',
    data: {
      labels: [
        ['第1期', '20/10 - 21/03'],
        ['第2期', '21/04 - 22/03'],
        ['第3期', '22/04 - 23/03'],
        ['第4期', '23/04 - 24/03'],
        ['第5期(目標値)', '24/04 - 25/03'],
      ],
      datasets: [{
        label: "売上実績 (単位100万円)",
        data: [18, 1062, 1493, 1950, 2500],
        backgroundColor: [
          '#029b27',
          '#029b27',
          '#029b27',
          '#029b27',
          '#f85515',
        ],
      }],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        fontSize: 18,
      },
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        },
      },
    }      
  })
}

function counter(el){
  const score = el.dataset.numb;
  const countDown = () => {
    setTimeout(()=>{
      count += 100
      el.innerText = count.toLocaleString()
      if(count != score){
        countDown()
      }
    },10 + Math.floor(count / 200))
  }
  countDown()
}


const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh',`${vh}px`);
}

