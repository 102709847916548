import ScrollMagic from 'scrollmagic';
import {scrolEvents} from './default';
import {loading} from './loading';
import {index} from './index';
import {business} from './business';
import {vm} from './vm';
require("fslightbox");

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
	 controller =  new ScrollMagic.Controller();

  // LOADING 処理
  const keyName = 'visited';
  const keyValue = true;

  if (!sessionStorage.getItem(keyName)) {
      sessionStorage.setItem(keyName, keyValue);
      loading(controller , scene);
  } else {
			const load = document.getElementById('loading')
      if(load){
        load.remove()
      }
      index(controller , scene);
  }

	
  vm();
  scrolEvents(controller , scene);
  business(controller , scene);
});