import ScrollMagic from 'scrollmagic';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';
import gsap from 'gsap';
import * as imagesLoaded from 'imagesloaded';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function scrolEvents(controller , scene){
	const siteHeader = document.getElementById('site-header');
    if(siteHeader){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            .setClassToggle(siteHeader,'active').addTo(controller)
        )
    };
	
	const sitePageTop = document.getElementById('pagetop');
    if(sitePageTop){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            .setClassToggle('sitePageTop','active').addTo(controller)
        )
    }
	
    const scrolEvents = document.querySelectorAll(".scrollanime")
    scrolEvents.forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        //markers: true,
        start: "top bottom",
        end: "bottom top",
        once: true,
        toggleClass: {targets: el, className: "active"},
      });
    })

    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
		header: '#site-header',
        offset: 0,
		speed: 1000,
		easing: 'easeInOutQuart',
		speedAsDuration: true,
		durationMin: 1000
    });

    const tabsmenu = document.querySelectorAll('.tabs__top__tab li')
    tabsmenu.forEach((el)=>{
      el.addEventListener('click', { el: el, all: tabsmenu, handleEvent: opener })
    })
}
function opener(){
  const elem = this.el
  const all = this.all
  const tabsBox = document.querySelectorAll('.tabs__box')
  const dataset = elem.dataset.open

  all.forEach((el) => {
    el.classList.remove('active')
  })
  elem.classList.add('active')

  tabsBox.forEach((el) => {
    if(el.dataset.open == dataset){
      el.classList.remove('invisible')
    }else{
      el.classList.add('invisible')
    }
  })
}