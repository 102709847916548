import * as imagesLoaded from 'imagesloaded';
import {index} from './index';

export function loading(){
  const loading = document.getElementById('loading')
  const img = document.querySelectorAll('img')

  if(loading){
    imagesLoaded(img, function(){
      showDispay(loading);
    })
  }else{
    imagesLoaded(img, function(){
      index()
    })
  }
};

function showDispay(el){
  el.classList.add('active')
  setTimeout(()=>{
    el.classList.add('remove')
    index()
  }, 4000)

  
  setTimeout(()=>{
    el.remove()
  }, 5000)

}